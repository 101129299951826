import Interval from './Interval';
import TimeSummary from './TimeSummary';
import TimeTriplet from './TimeTriplet';

class DayReport extends TimeSummary {
  date: string;

  dayOfWeek: string;

  lastChangeOn: string;

  intervals: Interval[];

  facilityDayType: string;

  isWorkDay: boolean;

  keyWord: string;

  pause: string;

  contractDays: number;

  contractHours: string;

  constructor(
      date: string,
      dayOfWeek: string,
      lastChangedOn: string,
      intervals: Interval[],
      facilityDayType: string,
      isWorkDay: boolean,
      keyWord: string,
      targetTimeDetailed: TimeTriplet,
      actualTimeDetailed: TimeTriplet,
      balanceDetailed: TimeTriplet,
      targetTimeTotal: string,
      actualTimeTotal: string,
      balanceTotal: string,
      pause: string,
      contractDays: number,
      contractHours: string,
  ) {
    super(
        targetTimeDetailed,
        actualTimeDetailed,
        balanceDetailed,
        targetTimeTotal,
        actualTimeTotal,
        balanceTotal,
    );
    this.date = date;
    this.dayOfWeek = dayOfWeek;
    this.lastChangeOn = lastChangedOn;
    this.intervals = intervals;
    this.facilityDayType = facilityDayType;
    this.isWorkDay = isWorkDay;
    this.keyWord = keyWord;
    this.pause = pause;
    this.contractDays = Math.round(contractDays * 100) / 100;
    this.contractHours = contractHours;
  }

  keyWordTranslation = (language: string = 'de'): string => {
    if (this.keyWord === null || this.keyWord === undefined || this.keyWord === 'NONE') return '';
    switch (language) {
      case 'en':
        switch (this.keyWord) {
          case 'NONE':
            return '';
          case 'HOLIDAY':
            return 'vacation';
          case 'HOLIDAY_SPECIAL':
            return 'special vacation';
          case 'HOLIDAY_HALF':
            return 'half vacation';
          case 'SICK':
            return 'sick';
          case 'SICK_CHILD':
            return 'sick child';
          case 'AZ_COMPENSATION':
            return 'work time compensation';
          case 'SCHOOL':
            return 'school';
          case 'EDUCATION':
            return 'special vacation';
          case 'FEIERTAG_FULL_COMPENSATION':
            return 'regeneration day';
          case 'WORKING_FACILITY_CLOSED_DAY':
            return 'working facility closed day';
          case 'NO_TIME_ADDITION':
            return 'no time addition';
          case 'PROHIBITION_OF_WORK':
            return 'Prohibition of work';
          case 'REHABILITATION':
            return 'Rehabilitation';
          case 'PARENTAL_LEAVE':
            return 'Parental leave';
          case 'MATERNITY_PROTECTION':
            return 'Maternity protection';
          default:
            return 'unknown';
        }
      default:
        switch (this.keyWord) {
          case 'NONE':
            return '';
          case 'HOLIDAY':
            return 'Urlaub';
          case 'HOLIDAY_SPECIAL':
            return 'Sonderurlaub';
          case 'HOLIDAY_HALF':
            return 'Urlaub halb';
          case 'HOLIDAY_UNPAID':
            return 'Urlaub unbezahlt';
          case 'SICK':
            return 'Krank';
          case 'SICK_ATTEST':
            return 'Krank mit Attest';
          case 'SICK_CHILD':
            return 'Kind krank';
          case 'AZ_COMPENSATION':
            return 'AZ Ausgleich';
          case 'SCHOOL':
            return 'Schule';
          case 'SCHOOL_INDIVIDUAL':
            return 'Schule individuell';
          case 'EDUCATION':
            return 'Fortbildung';
          case 'EDUCATION_K1':
            return 'Fortbildung K1';
          case 'EDUCATION_INDIVIDUAL':
            return 'Fortbildung individuell';
          case 'REGENERATION':
            return 'Regenerationstag';
          case 'CONVERSION_DAY':
            return 'Umwandlungstag';
          case 'FEIERTAG_FULL_COMPENSATION':
            return 'Feiertag Lohnausgleich';
          case 'WORKING_FACILITY_CLOSED_DAY':
            return 'Arbeit am Schließt.';
          case 'NO_TIME_ADDITION':
            return 'kein Zeit zuschlag';
          case 'PROHIBITION_OF_WORK':
            return 'Beschäftigungsverbot';
          case 'REHABILITATION':
            return 'Reha';
          case 'PARENTAL_LEAVE':
            return 'Elternzeit';
          case 'MATERNITY_PROTECTION':
            return 'Mutterschutz';
          default:
            return 'Unbekannt';
        }
    }
  };

  dayTypeTranslation = (language: string = 'de'): string => {
    switch (language) {
      case 'en':
        switch (this.facilityDayType) {
          case 'FACILITY_WORKDAY':
            return 'facility workday';
          case 'FACILITY_CLOSED_DAY':
            return 'facility closed day';
          case 'FEIERTAG':
            return 'national holiday';
          case 'USER_PROVIDED_FEIERTAG':
            return 'user provided holiday';
          case 'FACILITY_HOLIDAY':
            return 'official facility holiday';
          default:
            return 'unknown';
        }
      default:
        switch (this.facilityDayType) {
          case 'FACILITY_WORKDAY':
            return 'Einrichtung geöffnet';
          case 'FACILITY_CLOSED_DAY':
            return 'Einrichtung geschlossen';
          case 'FEIERTAG':
            return 'Feiertag';
          case 'USER_PROVIDED_FEIERTAG':
            return 'Nutzer definierter Feiertag';
          case 'FACILITY_HOLIDAY':
            return 'Einrichtungsurlaub';
          default:
            return 'Unbekannter Tagestyp';
        }
    }
  };
}

export default DayReport;
