import React, { useState } from 'react';
import WeekReport from '../../../utility/WeekReport';
import DayOverview from './DayOverview';

interface WeekOverviewProps {
    weekReport: WeekReport;
    weekIndex: number;
    language?: 'en' | 'de';
    employeeId: string; // <-- Pass employeeId down
}

const WeekOverview: React.FC<WeekOverviewProps> = ({
                                                       weekReport,
                                                       weekIndex,
                                                       language = 'de',
                                                       employeeId,
                                                   }) => {
    const [showDays, setShowDays] = useState(false);

    const {
        numberWorkingDays,
        targetTimeTotal,
        actualTimeTotal,
        balanceTotal,
        week,
    } = weekReport;

    return (
        <div className="card mb-3">
            <div className="card-header">
                <strong>{language === 'en' ? 'Week' : 'Woche'} {weekIndex}</strong>
            </div>
            <div className="card-body">

                {/* 1) Put "Working Days", "Soll", "Ist", "Saldo" in a row-based grid */}
                <div className="row row-cols-2 row-cols-md-4 g-2">
                    <div className="col">
                        <strong>{language === 'en' ? 'Working Days:' : 'Arbeitstage:'}</strong>{' '}
                        {numberWorkingDays}
                    </div>
                    <div className="col">
                        <strong>{language === 'en' ? 'Target (Week):' : 'Soll (Woche):'}</strong>{' '}
                        {targetTimeTotal}
                    </div>
                    <div className="col">
                        <strong>{language === 'en' ? 'Actual (Week):' : 'Ist (Woche):'}</strong>{' '}
                        {actualTimeTotal}
                    </div>
                    <div className="col">
                        <strong>{language === 'en' ? 'Balance (Week):' : 'Saldo (Woche):'}</strong>{' '}
                        {balanceTotal}
                    </div>
                </div>

                {/* 2) Button to toggle Day details */}
                <button
                    className="btn btn-sm btn-secondary mt-3"
                    onClick={() => setShowDays(!showDays)}
                >
                    {showDays
                        ? language === 'en'
                            ? 'Hide Days'
                            : 'Tage ausblenden'
                        : language === 'en'
                            ? 'Show Days'
                            : 'Tage anzeigen'}
                </button>

                {/* 3) Day cards in a grid */}
                {showDays && (
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mt-3">
                        {week.map((dayReport, idx) => (
                            <div className="col" key={idx}>
                                <DayOverview
                                    dayReport={dayReport}
                                    language={language}
                                    employeeId={employeeId} // <-- Pass employeeId here
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default WeekOverview;