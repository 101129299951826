import React, { useState } from 'react';
import TimeReport from '../../../utility/TimeReport';
import WeekOverview from './WeekOverview';
import { useParams } from 'react-router-dom';

interface MonthReportResponsiveProps {
    timeReport: TimeReport;
    language?: 'en' | 'de';
}

/**
 * This component gives a responsive, collapsible overview of a monthly time report.
 */
const MonthReportResponsive: React.FC<MonthReportResponsiveProps> = ({
                                                                         timeReport,
                                                                         language = 'de',
                                                                     }) => {
    // States for toggling collapsibles
    const [showWeekDetails, setShowWeekDetails] = useState(false);
    const [showWorkdays, setShowWorkdays] = useState(false);
    const [showOtherBalances, setShowOtherBalances] = useState(false);

    const { employeeId } = useParams<{ employeeId: string }>();

    // Destructure fields from the timeReport
    const {
        startDate,
        endDate,
        nameFacility,
        firstNameEmployee,
        lastNameEmployee,
        statementOfCopyRight,
        versionNumber,
        printDate,
        paidOutHoursTotal,
        receivedHoursTotal,
        numberWorkdaysInPeriod,
        vacationDays,
        vacationDaysTakenInPeriod,
        vacationDaysLeft,
        specialVacationDays,
        specialVacationDaysTakenInPeriod,
        specialVacationDaysLeft,
        regenerationDays,
        regenerationDaysTakenInPeriod,
        regenerationDaysLeft,
        conversionDays,
        conversionDaysTakenInPeriod,
        conversionDaysLeft,
        sickDays,
        workTimeCompensationDays,
        dependentBalanceTotal,
        dependentBalanceTotalLastMonth,
        targetTimeTotal,
        actualTimeTotal,
        balanceTotal,
        weeks,
    } = timeReport;

    return (
        <div className="mt-4 mb-5">
            {/* Header Section */}
            <h4>{language === 'en' ? 'Monthly Overview' : 'Monatsübersicht'}</h4>
            <div className="mb-3">
                <p className="mb-0">
                    <strong>{language === 'en' ? 'Facility:' : 'Einrichtung:'}</strong> {nameFacility}
                </p>
                <p className="mb-0">
                    <strong>{language === 'en' ? 'Employee:' : 'Mitarbeiter:in:'}</strong> {firstNameEmployee}{' '}
                    {lastNameEmployee}
                </p>
                <p className="mb-0">
                    <strong>{language === 'en' ? 'Period:' : 'Zeitraum:'}</strong> {startDate} - {endDate}
                </p>
            </div>

            {/* Summary Cards */}
            <div className="row">
                {/* Time Balances */}
                <div className="col-md-4 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <h5>{language === 'en' ? 'Time Balances' : 'Zeitguthaben'}</h5>
                            <p>
                                <strong>{language === 'en' ? 'Target:' : 'Soll:'}</strong> {targetTimeTotal}
                            </p>
                            <p>
                                <strong>{language === 'en' ? 'Actual:' : 'Ist:'}</strong> {actualTimeTotal}
                            </p>
                            <p>
                                <strong>{language === 'en' ? 'Balance:' : 'Saldo:'}</strong> {balanceTotal}
                            </p>
                            <hr />
                            <p>
                                <strong>
                                    {language === 'en' ? 'Dependent (This Month):' : 'Saldo (diesen Monat):'}
                                </strong>{' '}
                                {dependentBalanceTotal}
                            </p>
                            <p>
                                <strong>
                                    {language === 'en' ? 'Dependent (Last Month):' : 'Saldo (letzter Monat):'}
                                </strong>{' '}
                                {dependentBalanceTotalLastMonth}
                            </p>
                        </div>
                    </div>
                </div>

                {/* Workdays / Absences */}
                <div className="col-md-4 mb-3">
                    <button
                        className="btn btn-sm btn-primary mb-2"
                        onClick={() => setShowWorkdays(!showWorkdays)}
                    >
                        {showWorkdays
                            ? language === 'en'
                                ? 'Hide Workdays / Absences'
                                : 'Arbeitstage / Abwesenheiten ausblenden'
                            : language === 'en'
                                ? 'Show Workdays / Absences'
                                : 'Arbeitstage / Abwesenheiten anzeigen'}
                    </button>

                    {showWorkdays && (
                        <div className="card">
                            <div className="card-body">
                                <h5>
                                    {language === 'en' ? 'Workdays / Absences' : 'Arbeitstage / Abwesenheiten'}
                                </h5>
                                <p>
                                    <strong>{language === 'en' ? 'Workdays:' : 'Arbeitstage:'}</strong>{' '}
                                    {numberWorkdaysInPeriod}
                                </p>
                                <p>
                                    <strong>{language === 'en' ? 'Sick Days:' : 'Kranktage:'}</strong> {sickDays}
                                </p>
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Worktime Compensation:' : 'AZ-Ausgleichstage:'}
                                    </strong>{' '}
                                    {workTimeCompensationDays}
                                </p>
                                <hr />
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Paid Out Hours:' : 'Ausgezahlte Stunden:'}
                                    </strong>{' '}
                                    {paidOutHoursTotal}
                                </p>
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Received Hours:' : 'Erhaltene Stunden:'}
                                    </strong>{' '}
                                    {receivedHoursTotal}
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                {/* Additional Balances */}
                <div className="col-md-4 mb-3">
                    <button
                        className="btn btn-sm btn-primary mb-2"
                        onClick={() => setShowOtherBalances(!showOtherBalances)}
                    >
                        {showOtherBalances
                            ? language === 'en'
                                ? 'Hide Additional Balances'
                                : 'Urlaub / Regeneration ausblenden'
                            : language === 'en'
                                ? 'Show Additional Balances'
                                : 'Urlaub / Regeneration anzeigen'}
                    </button>

                    {showOtherBalances && (
                        <div className="card">
                            <div className="card-body">
                                <h5>{language === 'en' ? 'Additional Balances' : 'Urlaub / Regeneration'}</h5>
                                {/* Vacation */}
                                <p>
                                    <strong>{language === 'en' ? 'Vacation (Total):' : 'Urlaub (Gesamt):'}</strong>{' '}
                                    {vacationDays}
                                </p>
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Vacation Taken:' : 'Genommener Urlaub:'}
                                    </strong>{' '}
                                    {vacationDaysTakenInPeriod}
                                </p>
                                <p>
                                    <strong>{language === 'en' ? 'Vacation Left:' : 'Resturlaub:'}</strong>{' '}
                                    {vacationDaysLeft}
                                </p>
                                <hr />
                                {/* Special Vacation */}
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Special Vacation (Total):' : 'Sonderurlaub (Gesamt):'}
                                    </strong>{' '}
                                    {specialVacationDays}
                                </p>
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Special Vacation Taken:' : 'Genommener Sonderurlaub:'}
                                    </strong>{' '}
                                    {specialVacationDaysTakenInPeriod}
                                </p>
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Special Vacation Left:' : 'Rest Sonderurlaub:'}
                                    </strong>{' '}
                                    {specialVacationDaysLeft}
                                </p>
                                <hr />
                                {/* Regeneration */}
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Regeneration (Total):' : 'Regeneration (Gesamt):'}
                                    </strong>{' '}
                                    {regenerationDays}
                                </p>
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Regeneration Taken:' : 'Genommene Regeneration:'}
                                    </strong>{' '}
                                    {regenerationDaysTakenInPeriod}
                                </p>
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Regeneration Left:' : 'Restliche Regeneration:'}
                                    </strong>{' '}
                                    {regenerationDaysLeft}
                                </p>
                                <hr />
                                {/* Conversion */}
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Conversion (Total):' : 'Umwandlung (Gesamt):'}
                                    </strong>{' '}
                                    {conversionDays}
                                </p>
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Conversion Taken:' : 'Genommene Umwandlung:'}
                                    </strong>{' '}
                                    {conversionDaysTakenInPeriod}
                                </p>
                                <p>
                                    <strong>
                                        {language === 'en' ? 'Conversion Left:' : 'Restliche Umwandlung:'}
                                    </strong>{' '}
                                    {conversionDaysLeft}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Week Details */}
            <button
                className="btn btn-primary mb-3"
                onClick={() => setShowWeekDetails(!showWeekDetails)}
            >
                {showWeekDetails
                    ? language === 'en'
                        ? 'Hide Week Details'
                        : 'Wochendetails ausblenden'
                    : language === 'en'
                        ? 'Show Week Details'
                        : 'Wochendetails anzeigen'}
            </button>

            {showWeekDetails && weeks?.length > 0 && (
                <div>
                    {weeks.map((weekReport, i) => (
                        <WeekOverview
                            key={i}
                            weekReport={weekReport}
                            weekIndex={i + 1}
                            language={language}
                            employeeId={employeeId}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default MonthReportResponsive;