import React, { useEffect, useState } from 'react';
import { Paginated } from '@feathersjs/feathers';
import { observer } from 'mobx-react';
import {
  Alert,        // <-- Import Alert for success message
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useObservable } from 'react-use-observable';
import { useAsyncFn } from 'react-use';
import moment from 'moment';
import { uuid } from 'uuidv4';
import { FacilityYearSettings } from '../../../apis/attributes/facility-yearsettings';
import { social } from '../../../apis/social';
import InfoModal from './InfoModal';

import { TimeInterval } from '../../TimeInterval/containers/Availability';
import AdditionalFeastDaysLogic from './AdditionalFeastDaysLogic';
import V2ClosedDaysLogic from './ClosedDaysLogic';

const getEmptyFacilityYearSetting = (
    facilityId: string,
    targetYear = new Date().getFullYear(),
): FacilityYearSettings => ({
  additionalFeastDays: [],
  facilityClosedDays: [],
  facilityId,
  id: `${facilityId}_${targetYear}`,
  year: targetYear,
});

type ClosedDaysIntervals = TimeInterval[];
type FeastDaysIntervals = Pick<TimeInterval, 'startDate' | 'id' | 'note'>[];

const getClosedDaysBlankIntervals = (): ClosedDaysIntervals => [
  {
    endDate: undefined,
    id: uuid(),
    startDate: undefined,
  },
];
const getFeastDaysBlankIntervals = (): FeastDaysIntervals => [
  {
    id: uuid(),
    note: '',
    startDate: undefined,
  },
];

const convertClosedDaysToDbDate = (
    data: ClosedDaysIntervals | null | undefined,
): ClosedDaysIntervals => {
  if (!data) {
    return getClosedDaysBlankIntervals();
  }
  return data.map((interval) => ({
    endDate: !interval?.endDate ? undefined : moment(interval.endDate).toDate(),
    id: interval.id,
    note: !interval?.note ? undefined : interval.note,
    startDate: !interval?.startDate ? undefined : moment(interval.startDate).toDate(),
  }));
};

const convertFeastDaysToDbDate = (
    data: FeastDaysIntervals | null | undefined,
): ClosedDaysIntervals => {
  if (!data) {
    return getFeastDaysBlankIntervals();
  }
  return data.map((interval) => ({
    id: interval.id,
    note: !interval?.note ? undefined : interval.note,
    startDate: !interval?.startDate ? undefined : moment(interval.startDate).toDate(),
  }));
};

/**
 * fill a potentially fragmented YearSettingsList from DB with missing years and next year
 * @param yearSettings potentially non-existent DB YearSettings collection
 * @param facilityId id of the facility
 */
const patchYearlySettings = (
    yearSettings: FacilityYearSettings[] | undefined,
    facilityId: string,
): FacilityYearSettings[] => {
  // The last year in the list is the current year plus one
  const lastYearInList = new Date().getFullYear() + 1;

  // If there are no yearly settings, create a new one for the current year and the next year
  if (yearSettings === undefined) {
    return [
      getEmptyFacilityYearSetting(facilityId, lastYearInList - 1),
      getEmptyFacilityYearSetting(facilityId, lastYearInList),
    ];
  }

  // The first year is either the first year in the list or the current year
  const firstYearInList = yearSettings.length > 0 ? yearSettings[0].year : new Date().getFullYear();

  // Edge case: if someone fills out next year before this year
  if (yearSettings.length === 1 && firstYearInList === lastYearInList) {
    return [getEmptyFacilityYearSetting(facilityId, lastYearInList - 1), yearSettings[0]];
  }

  const returnValue: FacilityYearSettings[] = [];

  // Go over all years from the firstYearInList to this year + 1
  for (let year = firstYearInList; year <= lastYearInList; year++) {
    // Search for a year that could be in the DB, if not there, create empty
    const existing = yearSettings.find((ys) => ys.year === year);
    if (!existing) {
      returnValue.push(getEmptyFacilityYearSetting(facilityId, year));
    } else {
      returnValue.push(existing);
    }
  }

  return returnValue;
};

export const FacilityYearlyLogic: React.FC<{ facilityId: string }> = observer(({ facilityId }) => {
  const { t } = useTranslation();

  // Year chosen by the user
  const [chosenYear, setChosenYear] = useState<number | undefined>(new Date().getFullYear());

  // State to show success message
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

  const [allYearsSettings] = useObservable<Paginated<FacilityYearSettings> | null>(
      () =>
          social
              .service('facility-yearly-settings')
              .watch()
              .find({
                query: {
                  $sort: {
                    year: 1,
                  },
                  facilityId,
                },
              }),
      [facilityId],
  );

  // Generate padded list of years
  const paddedYearlySettings = patchYearlySettings(allYearsSettings?.data, facilityId);

  const [groupIntervalsClosedDays, setGroupIntervalsClosedDays] = useState<ClosedDaysIntervals>();
  const [groupIntervalsFeastDays, setGroupIntervalsFeastDays] = useState<FeastDaysIntervals>();

  const [forceUpdate, setForceUpdate] = useState(false);

  // Async function to fetch or init facility-yearly-settings
  const [_, retryFacility] = useAsyncFn(async () => {
    if (!facilityId) return undefined;

    const res = (await social
        .service('facility-yearly-settings')
        .find({
          query: {
            $limit: 1,
            facilityId,
            year: chosenYear,
          },
        })
        .catch(() => undefined)) as Paginated<FacilityYearSettings>;

    if (res?.total) {
      // We got data for that year
      setGroupIntervalsClosedDays(undefined);
      setForceUpdate(true);

      setTimeout(() => {
        setGroupIntervalsClosedDays(convertClosedDaysToDbDate(res.data[0].facilityClosedDays));
        setGroupIntervalsFeastDays(convertFeastDaysToDbDate(res.data[0].additionalFeastDays));
        setForceUpdate(false);
      }, 0);
    } else {
      // No data for that year, so create blank intervals
      setForceUpdate(true);
      setGroupIntervalsClosedDays(undefined);
      setGroupIntervalsFeastDays(undefined);

      setTimeout(() => {
        setGroupIntervalsClosedDays(getClosedDaysBlankIntervals());
        setGroupIntervalsFeastDays(getFeastDaysBlankIntervals());
        setForceUpdate(false);
      }, 0);
    }

    return res;
  }, [facilityId, chosenYear]);

  // On facility or chosenYear change, re-init intervals
  useEffect(() => {
    setGroupIntervalsClosedDays(getClosedDaysBlankIntervals());
    setGroupIntervalsFeastDays(getFeastDaysBlankIntervals());
    retryFacility();
  }, [facilityId, chosenYear]);

  // Submit (create or update) the current year's settings
  const submitYearlySettings = async () => {
    const res = (await social
        .service('facility-yearly-settings')
        .find({
          query: {
            $limit: 1,
            facilityId,
            year: chosenYear,
          },
        })
        .catch(() => undefined)) as Paginated<FacilityYearSettings>;

    if (res?.total) {
      // Patch existing
      await social.service('facility-yearly-settings').patch(res.data[0].id, {
        additionalFeastDays: convertFeastDaysToDbDate(groupIntervalsFeastDays),
        facilityClosedDays: convertClosedDaysToDbDate(groupIntervalsClosedDays),
      });
    } else {
      // Create new
      await social.service('facility-yearly-settings').create({
        additionalFeastDays: convertFeastDaysToDbDate(groupIntervalsFeastDays),
        facilityClosedDays: convertClosedDaysToDbDate(groupIntervalsClosedDays),
        facilityId,
        year: chosenYear,
      });
    }

    // Show success message
    setSaveSuccess(true);

    // Hide success message after 3 seconds
    setTimeout(() => setSaveSuccess(false), 3000);
  };

  return (
      <>
        <div style={{ height: '40px' }} />

        <div className="w-100 mt-4 mb-4 d-flex flex-row justify-content-start align-items-center">
          <h4 className="pr-3 h-100 my-0 font-weight-bold" style={{ minWidth: '200px' }}>
            {t('YEARLY_SETTINGS')}{' '}
            <InfoModal content={t('YEARLY_SETTINGS_MODAL') as string} />
          </h4>
        </div>

        <Container className="mt-4 shadow py-4 px-5 rounded" style={{ minHeight: '376px' }}>
          {/* Display success feedback if saveSuccess is true */}
          {saveSuccess && (
              <Alert variant="success" className="mb-4">
                {t('SAVE_YEAR_UPDATES_SUCCESS') /* Add this key in your translation file */}
              </Alert>
          )}

          <Row className="mb-4">
            <Col>
              <DropdownButton style={{ width: '100%' }} menuAlign="left" title={chosenYear}>
                {paddedYearlySettings.map((y, idx) => (
                    <Dropdown.Item
                        className="handStyle"
                        key={`${y.year}`}
                        eventKey={idx.toString()}
                        onClick={() => {
                          setChosenYear(undefined);
                          setTimeout(() => {
                            setChosenYear(y.year);
                          }, 0);
                        }}
                    >
                      {y.year}
                    </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <h5 className="mt-2">{t('FACILITY_CLOSED_DAYS')}</h5>
            </Col>
          </Row>

          {!forceUpdate && groupIntervalsClosedDays && (
              <V2ClosedDaysLogic
                  disabled={false}
                  key="closeddays"
                  dayStr=""
                  timeIntervals={groupIntervalsClosedDays ?? []}
                  onSubmit={(intervals) => {
                    setGroupIntervalsClosedDays(intervals);
                  }}
                  year={chosenYear as number}
              />
          )}

          <Row className="mt-5 mb-2">
            <Col>
              <h5>{t('ADDITIONAL_FEAST_DAYS')}</h5>
            </Col>
          </Row>

          {!forceUpdate && groupIntervalsFeastDays && (
              <AdditionalFeastDaysLogic
                  disabled={false}
                  key="feastdays"
                  timeIntervals={groupIntervalsFeastDays ?? []}
                  onSubmit={(intervals) => {
                    setGroupIntervalsFeastDays(intervals);
                  }}
                  year={chosenYear as number}
              />
          )}
        </Container>

        <Row className="mb-4">
          <Col />
        </Row>

        <div className="w-100 d-flex justify-content-between align-items-center">
          <p className="text-info">{t('SAVE_BEFORE_NEW_YEAR_FACILITY')}</p>

          <Button className="mt-5 mb-5 py-2" type="submit" onClick={submitYearlySettings}>
            {t('SAVE_YEAR_UPDATES')}
          </Button>
        </div>
      </>
  );
});

export default FacilityYearlyLogic;