// disable eslint file
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Col, Container, Dropdown, Modal, Row } from 'react-bootstrap';

import de from 'date-fns/locale/de';
import Collapsible from 'react-collapsible';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-promise';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { uuid } from 'uuidv4';
import social, { authenticatedEmployee } from '../../../apis/social';
import { TimeInterval } from '../../TimeInterval/containers/Availability';

import { useGlobalState } from '../../../App';
import {
  calculatePauseStat,
  calculateStat,
  checkForEmployeeWorkDay,
  formatDuration,
  sumTimeParts,
} from '../../../utility/time';
import InfoModal from '../../Facility/containers/InfoModal';
import ExecuteModalBtn from '../../Payment/containers/ExecuteModalBtn';
import DaySection from '../../TimeInterval/containers/DaySection';
import DuplicateDate from './DuplicateDayRange';
import { getDayTypeBadge } from './ExtendedOverviewSection';
import TopInlineOverviewSection from './TopInlineOverviewSection';
import { useObservable } from 'react-use-observable';
import { TariffListAttributes } from '../../TariffCreation/containers/Overview';
import {
  DayAttributes,
  EmployeeAttributes,
  EmploymentAttributes,
  TariffAttributes,
} from '../../../apis/attributes';
import { parseTariffAttributes } from '../../../core/tariff';
import { TariffCollection } from '../../../core/tariffCollection';
import { parseEmploymentAttributes } from '../../../core/employment';
import { EmploymentCollection } from '../../../core/employmentCollection';
import {
  generateDateKeyMapForYear,
  updateDatesInFlattenedValues,
} from '../../../core/utility';
import { DateTime } from 'luxon';
import { Paginated } from '@feathersjs/feathers';
import { YearlySickNotesDB } from '../../../apis/attributes/yearly-sick-notes';
import { SickNoteCollection } from '../../../core/SickNoteCollection';
import DayDetailsSection from './DayDetails';

registerLocale('de', de);

type DayViewProps = {
  defaultDate?: Date;
};

type DayIntervalType = {
  dayType: string;
  freeIntervals: TimeInterval[];
};

function getAllDayAttributes(allDays: any | undefined, startDate: Date): DayAttributes[] {
  const values: DayAttributes[][] = [];
  const keyMap = generateDateKeyMapForYear(startDate.getFullYear());

  if (allDays) {
    for (let i = 0; i < 4; i++) {
      values.push(allDays[i].data);
    }

    let flattenedValues = values.flat();
    flattenedValues = updateDatesInFlattenedValues(flattenedValues, keyMap);

    return flattenedValues.sort((a: DayAttributes, b: DayAttributes) => {
      const aDate = a.date;
      const bDate = b.date;
      if (aDate && bDate) {
        return aDate.toISODate().localeCompare(bDate.toISODate());
      }
      return 0;
    });
  }

  return [];
}

const getBlankIntervals = () => ({
  dayType: '',
  freeIntervals: [
    {
      endDate: undefined,
      id: uuid(),
      startDate: undefined,
    },
  ],
});

export const options = [
  { groupId: 0, label: 'freilassen', value: undefined },
  { groupId: 1, label: 'krank', value: 'SICK' },
  { groupId: 1, label: 'krank Kind', value: 'SICK_CHILD' },
  { groupId: 1, label: 'krank mit Attest', value: 'SICK_ATTEST' },
  { groupId: 2, label: 'Urlaub', value: 'HOLIDAY' },
  { groupId: 2, label: 'Sonderurlaub', value: 'HOLIDAY_SPECIAL' },
  { groupId: 2, label: 'Regenerationstag', value: 'REGENERATION' },
  { groupId: 2, label: 'Urlaub halber Tag', value: 'HOLIDAY_HALF' },
  {
    groupId: 2,
    label: 'unbezahlter Urlaub',
    value: 'HOLIDAY_UNPAID',
  },
  { groupId: 2, label: 'Umwandlungstag', value: 'CONVERSION_DAY' },
  {
    groupId: 2,
    label: 'Arbeit an Schließtag',
    value: 'WORKING_FACILITY_CLOSED_DAY',
  },
  { groupId: 2, label: 'Beschäftigungsverbot', value: 'PROHIBITION_OF_WORK' },
  { groupId: 2, label: 'Rehabilitation', value: 'REHABILITATION' },
  { groupId: 2, label: 'Elternzeit', value: 'PARENTAL_LEAVE' },
  { groupId: 2, label: 'Mutterschutz', value: 'MATERNITY_PROTECTION' },
  { groupId: 3, label: 'Fortbildung', value: 'EDUCATION' },
  { groupId: 3, label: 'Fortbildung K1', value: 'EDUCATION_K1' },
  {
    groupId: 3,
    label: 'Fortbildung individuell',
    value: 'EDUCATION_INDIVIDUAL',
  },
  { groupId: 3, label: 'Schule', value: 'SCHOOL' },
  {
    groupId: 3,
    label: 'Schule individuell',
    value: 'SCHOOL_INDIVIDUAL',
  },
  {
    groupId: 4,
    label: 'Arbeitszeitausgleich',
    value: 'AZ_COMPENSATION',
  },
  {
    groupId: 5,
    label: 'Feiertag Lohnausgleich',
    value: 'FEIERTAG_FULL_COMPENSATION',
  },
  { groupId: 5, label: 'Kein Zeitzuschlag', value: 'NO_TIME_ADDITION' },
];

const processDateStringsToDate = (data: DayIntervalType | null | undefined) => {
  if (!data) {
    return getBlankIntervals();
  }
  return {
    dayType: data?.dayType,
    freeIntervals: data?.freeIntervals?.map((interval) => ({
      endDate: !interval?.endDate ? undefined : moment(interval?.endDate)?.toDate(),
      id: interval?.id,
      note: !interval?.note ? undefined : interval?.note,
      startDate: !interval?.startDate ? undefined : moment(interval?.startDate).toDate(),
      type: !interval?.type ? undefined : interval?.type,
    })),
  };
};

export const isYearInPast = (currentDate: Date) =>
    currentDate.getFullYear() < new Date().getFullYear() - 2;

export const getDayOrDefault = (dd: string | null) => {
  if (!dd) {
    return moment().toDate();
  }
  return moment(dd).toDate();
};

export const DayView: React.FC<DayViewProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const dd = query.get('dd');

  const employeeId = useParams<{ employeeId: string }>().employeeId;

  const [startDate, setStartDate] = useState(getDayOrDefault(dd));
  const [groupIntervals, setgroupIntervals] = useState<DayIntervalType | undefined>(undefined);
  const [selected, setSelected] = useState<{ value: string; label: string }[]>([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [dayOfWeek, setDayOfWeek] = useState<number | undefined>(
      moment(dd ? new Date(dd) : new Date())?.day(),
  );
  const [weekOfMonth, setWeekOfMonth] = useState<number | undefined>(
      parseInt(moment(dd ? new Date(dd) : new Date())?.format('WW'), 10),
  );
  const [monthOfYear, setMonthOfYear] = useState<number | undefined>(
      moment(dd ? new Date(dd) : new Date())?.month(),
  );
  const [year, setYear] = useState<number | undefined>(
      moment(dd ? new Date(dd) : new Date())?.year(),
  );

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // For toggling the "Details" area:
  const [showDetails, setShowDetails] = useState(false);

  const [canWrite] = useGlobalState('canWrite');

  const { value } = usePromise(authenticatedEmployee());

  const [allSickNotes] = useObservable<Paginated<YearlySickNotesDB> | null>(
      () =>
          social
              .service('yearly-sick-notes')
              .watch()
              .find({
                query: {
                  $sort: {
                    year: 1,
                  },
                  employeeId,
                },
              }),
      [employeeId],
  );

  let parsedSickNotes: SickNoteCollection[] | undefined = undefined;
  let calledInSick = false;
  const today = DateTime.fromJSDate(startDate);

  // check if sicknotes are in db, if so, parse
  if (allSickNotes?.data?.length) {
    parsedSickNotes = allSickNotes?.data?.map((sickNote) =>
        SickNoteCollection.fromAttributes(sickNote),
    );
    parsedSickNotes.filter((sickNote) => sickNote.year === startDate.getFullYear());
    // check if a sicknote exists for today, meaning that the employee is sick today
    for (const sickNoteCollection of parsedSickNotes) {
      for (const sickNote of sickNoteCollection.sickNotes) {
        if (sickNote.start.startOf('day') <= today && sickNote.end.endOf('day') >= today) {
          calledInSick = true;
          break;
        }
      }
    }
  }

  // fetch tariffs from backend
  const [tariffs] = useObservable<TariffListAttributes | null>(
      () =>
          social
              .service('tariff')
              .watch()
              .find({
                query: {
                  $sort: {
                    createdAt: 1,
                  },
                  facilityId: (value as any)?.user?.employee?.facilityId,
                  finalSubmission: true,
                },
              }) as any,
      [value],
  );

  // fetch employments from backend
  const [employments] = useObservable<EmploymentAttributes | null>(
      () =>
          social
              .service('employment')
              .watch()
              .find({
                query: {
                  $sort: {
                    createdAt: 1,
                  },
                  employeeId,
                },
              }) as any,
      [value],
  );

  // get employee
  const [employee] = useObservable<EmployeeAttributes | null>(
      () => social.service('employee').watch().get(employeeId) as any,
      [value],
  );
  const canEdit = (employee?.adminCanEdit && canWrite) || (value as any)?.user?.role === 'EMPLOYEE';

  // parse tariffs to tariff collection
  const rawTariffs = tariffs?.data ?? [];
  const parsedTariffs = rawTariffs.map((t: TariffAttributes) => parseTariffAttributes(t));
  const tariffCollection = new TariffCollection(parsedTariffs);

  // parse employments to employment collection
  let rawEmployments = (employments as any)?.data ?? [];
  // TODO: check if setting finalSubmission to true is correct, possibly remove
  rawEmployments = rawEmployments.filter(
      (e: any) => e?.start !== null && e?.finalSubmission === true,
  );
  const employmentCollection = new EmploymentCollection(
      rawEmployments.map((e: any) => parseEmploymentAttributes(e)),
  );

  const [day, retryDay] = useAsyncFn(async () => {
    if (
        (dayOfWeek ?? -1) < 0 ||
        !employeeId ||
        (monthOfYear ?? -1) < 0 ||
        (weekOfMonth ?? -1) < 0 ||
        !year
    ) {
      return undefined;
    }

    const res: any = (await social.service('day').find({
      query: {
        $limit: 1,
        dayOfWeek,
        employeeId,
        monthOfYear,
        weekOfMonth,
        year,
      },
    })) as any;

    if (res.total) {
      const newArr =
          res?.data?.[0]?.qualifyTag?.map((tag: any) => ({
            label: tag,
            value: tag,
          })) ?? [];
      setSelected(newArr);
      setgroupIntervals(undefined);

      setForceUpdate(true);
      setTimeout(() => {
        setgroupIntervals(processDateStringsToDate(res?.data?.[0]?.customIntervals) as any);
        setForceUpdate(false);
        // refetch
      }, 100);
    } else {
      setSelected([]);
      setgroupIntervals(undefined);

      setForceUpdate(true);
      setTimeout(() => {
        setgroupIntervals(getBlankIntervals() as any);
        setForceUpdate(false);
        // refetch
      }, 100);
    }

    return res as any;
  }, [value, (value as any)?.user, weekOfMonth, monthOfYear, year, dayOfWeek]);

// After
  const [allDays, retryAllDays] = useAsyncFn(async () => {
    // Do not shadow the employeeId. Use the one from useParams.
    if (!employeeId || !dd) {
      return undefined; // if admin but no employeeId in params, handle this separately
    }

    // Now safely use employeeId from params
    let allRecords: any[] = [];
    const limit = 100;

    try {
      for (let i = 0; i < 4; i++) {
        const res = await social.service('day').find({
          query: {
            $limit: limit,
            $skip: i * limit,
            employeeId, // from useParams
            year: startDate.getFullYear(),
          },
        });

        allRecords = allRecords.concat(res);
      }
      return allRecords ?? [];
    } catch (error) {
      console.error('Error fetching days:', error);
      return undefined;
    }
  }, [startDate, employeeId, dd]);

  // This needs to be updated to use the new getAllDayAttributes function
  const parsedDays = useMemo(() => {
    if (
        allDays?.value &&
        Array.isArray(allDays.value) &&
        allDays.value.every((item) => item.data && Array.isArray(item.data))
    ) {
      return getAllDayAttributes(allDays.value, startDate).filter((day) => day?.date > startDate);
    } else {
      return getAllDayAttributes(undefined, startDate);
    }
  }, [allDays]); // Dependencies: allDays and startDate

  const forceRetryDay = () => {
    setSelected([]);
    setgroupIntervals(getBlankIntervals() as any);
    retryDay();
  };

  useEffect(() => {
    // reinit selected intervals and tags
    forceRetryDay();
  }, [value, (value as any)?.user, weekOfMonth, monthOfYear, year, dayOfWeek]);

  useEffect(() => {
    // This will call the retryAllDays function to fetch all days when the component mounts
    // or when any of the dependencies change
    retryAllDays();
  }, [startDate, (value as any)?.user?.employee?.id, year]);

  const [, retryEmployment] = useAsyncFn(async () => {
    if (!employeeId) return undefined;
    const res: any = (await social.service('employment').find({
      query: {
        $limit: 1,
        $sort: { createdAt: -1 },
        employeeId,
      },
    })) as any;
    return res as any;
  }, [value, (value as any)?.user]);

  useEffect(() => {
    // refet ch
    retryEmployment();
  }, [value, (value as any)?.user]);

  const navigateTo = useCallback(
      (pathName: string) => {
        const newPathname = generatePath(`${pathName}/:date`, {
          date: moment(startDate).utc().add(2, 'hours').toDate().toISOString(),
        });
        // Use the history API to navigate to the new URL
        history.push(newPathname);
      },
      [startDate],
  );

  const submitDayIntervals = async () => {
    if (!day.value) return;

    if ((day?.value as any)?.data?.length > 0) {
      await social.service('day').patch((day?.value as any)?.data?.[0].id, {
        customIntervals: groupIntervals as any,
        qualifyTag: selected?.map((e) => e.value),
      });
    } else {
      await social.service('day').create({
        customIntervals: groupIntervals as any,
        dayOfWeek,
        employeeId,
        monthOfYear,
        qualifyTag: selected?.map((e) => e.value),
        weekOfMonth,
        year,
      });
    }

    const origin = window.location.origin; // This should be
    // Construct the full URL using the current origin

    navigateTo(`/employee/${employeeId}/dashboard`);
    // forceRetryDay();
  };

  // this makes the full query, including fetching of all datapoints and KTOR API call
  const [dayEmployeeStatistics, retryEmploymentStatistics] = useAsyncFn(async () => {
    // get database key of employee

    // get all data needed to do full query of ktor backend
    const res: any = await (social.service('employee') as any).patch(employeeId, {
      employeeId,
      endDate: startDate.toISOString(),
      startDate: startDate.toISOString(),
      statistics: true,
      buildMonthReport: false,
      buildYearReport: false,
    });
    return res as any;
  }, [value, (value as any)?.user, startDate?.toString()]);

  useEffect(() => {
    retryEmploymentStatistics();
  }, [value, (value as any)?.user, startDate?.toString()]);

  const dropdownOptions = options?.map((op) => (
      <Dropdown.Item
          key={uuid()}
          className='text-center'
          onClick={() => {
            if (op?.value === undefined) {
              setSelected([]);
            } else if (op?.value === 'FEIERTAG_FULL_COMPENSATION') {
              if (
                  dayEmployeeStatistics?.value?.dayStatusList?.[0]?.facilityDayType === 'FEIERTAG' ||
                  dayEmployeeStatistics?.value?.dayStatusList[0]?.facilityDayType ===
                  'USER_PROVIDED_FEIERTAG'
              ) {
                setSelected([op]);
              } else {
                setSelected([]);
              }
            } else if (op?.value === 'WORKING_FACILITY_CLOSED_DAY') {
              if (
                  dayEmployeeStatistics?.value?.dayStatusList?.[0]?.facilityDayType === 'FACILITY_HOLIDAY'
              ) {
                setSelected([op]);
              } else {
                setSelected([]);
              }
            } else {
              setSelected([op]);
            }
          }}
      >
        {op?.label}
      </Dropdown.Item>
  ));
  const spacedDropdownOptions = () => {
    const drops = dropdownOptions;
    const res = [];
    for (let i = 0; i < drops.length - 1; i++) {
      const item = options[i];
      const itemAfter = options[i + 1];
      res.push(drops[i]);
      if (item.groupId !== itemAfter.groupId) {
        res.push(<Dropdown.Divider key={uuid()} />);
      }
      if (i === drops.length - 2) {
        res.push(drops[i + 1]);
      }
    }
    return res;
  };

  return (
      <Container>
        {/* -- HEAD ROW: Datepicker, DuplicateDate, etc. -- */}
        <Row>
          {/* Your DatePicker Column */}
          <Col xs={4} className="d-flex flex-column justify-content-center">
            <DatePicker
                className="mt-3 ml-2 dayDatePicker"
                selected={startDate}
                locale={t('LANGUAGE') as string}
                onChange={(date: Date) => {
                  setStartDate(date);
                  const moDate = moment(date);
                  setDayOfWeek(moDate.day());
                  setWeekOfMonth(parseInt(moDate.format('WW'), 10));
                  setMonthOfYear(moDate.month());
                  setYear(moDate.year());
                }}
                dateFormat="EEEE, MMM d, yyyy"
            />
          </Col>

          {/* Middle Column */}
          <Col xs={6} />

          {/* DuplicateDate Column */}
          <Col xs={2} className="d-flex flex-column justify-content-center">
            <DuplicateDate
                disabled={!canEdit}
                sourceDate={startDate}
                sourceDay={
                  (day?.value as any)?.data.length
                      ? (day?.value as any)?.data[0]
                      : undefined
                }
                sourceDays={parsedDays}
                state={dayEmployeeStatistics.value?.state}
                dec24NotWorking={dayEmployeeStatistics.value?.dec24NotWorking}
                dec31NotWorking={dayEmployeeStatistics.value?.dec31NotWorking}
                tariffCollection={tariffCollection}
                employmentCollection={employmentCollection}
            />
          </Col>
        </Row>

        {/* -- Some top info (TopInlineOverviewSection, etc) -- */}
        {dayEmployeeStatistics ? (
            <TopInlineOverviewSection
                totalTimeVal={calculateStat(dayEmployeeStatistics?.value?.targetTime)}
                timeWorkedVal={calculateStat(
                    dayEmployeeStatistics?.value?.actualTime?.actualTimeAccepted
                )}
                balanceVal={formatDuration(
                    sumTimeParts(
                        dayEmployeeStatistics?.value?.balance.careTimePart,
                        dayEmployeeStatistics?.value?.balance.disposalTimeRegularPart,
                        dayEmployeeStatistics?.value?.balance.disposalTimeIrregularPart
                    )
                )}
                overtimeVal={calculatePauseStat(dayEmployeeStatistics?.value?.actualTime)}
                breakVal={1}
            />
        ) : (
            <TopInlineOverviewSection totalTimeVal="" timeWorkedVal="" balanceVal="" overtimeVal="" breakVal={1} />
        )}

        {getDayTypeBadge(
            dayEmployeeStatistics?.value?.dayStatusList?.[0]?.facilityDayType,
            t,
            calledInSick
        )}
        {checkForEmployeeWorkDay(dayEmployeeStatistics?.value?.dayStatusList) && (
            <Badge variant="primary">{t('YOUR_WORK_DAY') as string}</Badge>
        )}

        <br />
        <h5>
          {t('TIME_INTERVALS') as string}{' '}
          <strong>
            <InfoModal content={t('INTERVALS_INFO') as string} />
          </strong>
        </h5>

        {!forceUpdate && (
            <DaySection
                disabled={isYearInPast(startDate) || !canEdit}
                key={`${groupIntervals?.dayType}10`}
                dayStr={groupIntervals?.dayType ?? ''}
                timeIntervals={groupIntervals?.freeIntervals ?? []}
                onSubmit={(intervals) => {
                  const groupIntervalsArray = groupIntervals ?? ({} as any);
                  groupIntervalsArray.freeIntervals = intervals;
                  setgroupIntervals(groupIntervalsArray);
                }}
            />
        )}

        <hr className="mt-5 mb-4" style={{ backgroundColor: 'white', border: 'none', height: '8px' }} />

        {/* -- The new "Details" toggle button & content -- */}
        <Button
            variant="primary" // Updated to use 'btn-primary'
            onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails
              ? t('HIDE_DETAILS') || 'Hide Details'
              : t('SHOW_DETAILS') || 'Show Details'}
        </Button>

        {showDetails && dayEmployeeStatistics?.value && (
            <DayDetailsSection
                dayEmployeeStatistics={dayEmployeeStatistics}
                calledInSick={calledInSick}
            />
        )}

        {/* -- Qualify tag dropdown etc. -- */}
        <hr className="mt-5 mb-4" style={{ backgroundColor: 'white', border: 'none', height: '8px' }} />

        <p className="mb-1 mt-5">
          <strong>{t('QUALIFY_TAG') as string}</strong>
          <strong>
            <InfoModal content={t('KEYWORDS_INFO') as string} />
          </strong>
        </p>
        <Dropdown>
          <Dropdown.Toggle
              disabled={isYearInPast(startDate) || !canEdit}
              className="w-100"
              variant="success"
              id="dropdown-basic"
          >
            {selected?.length
                ? options?.find((obj) => obj?.value === selected?.[0].value)?.label
                : (t('SELECT_KEYWORD') as string)}
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-100">{spacedDropdownOptions()}</Dropdown.Menu>
        </Dropdown>

        {/* -- Modal logic (unchanged) -- */}
        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="contained-modal-title-hcenter">
              {t('NOT_WORKING_DAY') as string}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5 py-4">{t('CHANGE_OR_CLOSE') as string}</Modal.Body>
          <Modal.Footer className="d-flex justif-content-center">
            <Button
                block
                variant="primary"
                disabled={isYearInPast(startDate) || !canEdit}
                onClick={() => {
                  handleClose();
                  submitDayIntervals();
                }}
            >
              {t('WORKING_TODAY') as string}
            </Button>
          </Modal.Footer>
        </Modal>

        <div style={{ height: '100px' }} />

        {canWrite ? (
            <Button
                disabled={isYearInPast(startDate) || !canEdit}
                className="mt-5 py-2"
                block
                onClick={() => {
                  if (!checkForEmployeeWorkDay(dayEmployeeStatistics?.value?.dayStatusList))
                    handleShow();
                  else submitDayIntervals();
                }}
            >
              {t('COMPLETE_DAY') as string}
            </Button>
        ) : (
            <ExecuteModalBtn modalType="EMPLOYEE">
              <Button disabled={isYearInPast(startDate) || !canEdit} className="mt-5 py-2" block>
                {t('COMPLETE_DAY') as string}
              </Button>
            </ExecuteModalBtn>
        )}
      </Container>
  );
};

export default DayView;