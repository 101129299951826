import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import usePromise from 'react-promise';
import { useLocation, useParams } from 'react-router';
import { useAsyncFn } from 'react-use';
import social, { authenticatedEmployee } from '../../../apis/social';
// 1) PDF-based component
import MonthReport from '../../../components/MonthReport';
// 2) New responsive UI-based component
import MonthReportResponsive from './MonthReportResponsive';

import buildTimeReport from '../../../utility/reportbuild';

export const MonthView: React.FC = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const dd = query.get('dd');

    // Keep a local state for the chosen month (via datepicker)
    const [startDate, setStartDate] = useState(dd ? new Date(dd) : new Date());
    const { value } = usePromise(authenticatedEmployee());
    const employeeId = useParams<{ employeeId: string }>().employeeId;

    // Fetch logic to get monthly statistics
    const [monthEmployeeStatistics, retryMonthEmployment] = useAsyncFn(async () => {
        const res: any = await (social.service('employee') as any).patch(employeeId, {
            employeeId,
            endDate: moment(startDate).endOf('month').toISOString(),
            startDate: moment(startDate).startOf('month').toISOString(),
            statistics: true,
            buildMonthReport: true,
            buildYearReport: false,
        });
        return res as any;
    }, [value, (value as any)?.user, startDate?.toString()]);

    // Trigger fetch on mount or whenever relevant dependencies change
    useEffect(() => {
        retryMonthEmployment();
    }, [value, (value as any)?.user, employeeId, startDate?.toString()]);

    // Our container
    return (
        <Container>
            {/* DatePicker at the top */}
            <DatePicker
                className="mt-3 ml-2 monthDatePicker"
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
            />

            {/* Spacing */}
            <div style={{ height: '10px' }} />

            {/* New Responsive Monthly Overview */}
            {monthEmployeeStatistics?.value?.timeReport && (
                <MonthReportResponsive
                    timeReport={buildTimeReport(monthEmployeeStatistics?.value?.timeReport)}
                />
            )}

            {/* PDF-based MonthReport generation (placed below the display) */}
            <div className="mt-5">
                {monthEmployeeStatistics?.value?.timeReport && (
                    <MonthReport
                        timeReport={buildTimeReport(monthEmployeeStatistics?.value?.timeReport)}
                    />
                )}
            </div>
        </Container>
    );
};

export default MonthView;