import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DayReport from '../../../utility/DayReport';
import './DayOverview.css';

interface DayOverviewProps {
    dayReport: DayReport;
    language?: 'en' | 'de';
    employeeId?: string;
}

const DayOverview: React.FC<DayOverviewProps> = ({
                                                     dayReport,
                                                     language = 'de',
                                                     employeeId = '12345',
                                                 }) => {
    const [showIntervals, setShowIntervals] = useState(false);

    const {
        date,
        dayOfWeek,
        intervals,
        targetTimeTotal,
        actualTimeTotal,
        balanceTotal,
        facilityDayType,
    } = dayReport;

    // Utility function to normalize dayOfWeek
    const normalizeDayOfWeek = (day: string): string => {
        return day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
    };

    // Translate dayOfWeek
    const dayNames: Record<string, { en: string; de: string }> = {
        Monday: { en: 'Monday', de: 'Montag' },
        Tuesday: { en: 'Tuesday', de: 'Dienstag' },
        Wednesday: { en: 'Wednesday', de: 'Mittwoch' },
        Thursday: { en: 'Thursday', de: 'Donnerstag' },
        Friday: { en: 'Friday', de: 'Freitag' },
        Saturday: { en: 'Saturday', de: 'Samstag' },
        Sunday: { en: 'Sunday', de: 'Sonntag' },
    };

    const normalizedDayOfWeek = normalizeDayOfWeek(dayOfWeek);
    const dayOfWeekTranslated =
        dayNames[normalizedDayOfWeek]?.[language] || dayOfWeek;

    // Format date to dd.mm.yy
    const shortDate = new Date(date).toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
    });

    const facilityDayTypeTranslated = dayReport.dayTypeTranslation(language);
    const keyWordTranslated = dayReport.keyWordTranslation(language);

    const dayLink = `/employee/${employeeId}/day?dd=${new Date(date).toISOString()}`;

    // Determine the CSS class based on facilityDayType
    const dayTypeClass = (() => {
        switch (facilityDayType) {
            case 'FACILITY_WORKDAY': // Einrichtung geöffnet
                return 'day-type-open';
            case 'FACILITY_CLOSED_DAY': // Einrichtung geschlossen
                return 'day-type-closed';
            case 'FEIERTAG': // Feiertag
                return 'day-type-holiday';
            default:
                return '';
        }
    })();

    return (
        <Link to={dayLink} style={{ textDecoration: 'none' }}>
            <div
                className={`border p-3 mb-2 rounded h-100 d-flex flex-column justify-content-between day-overview-link ${dayTypeClass}`}
            >
                <div>
                    <strong>{dayOfWeekTranslated}</strong> - {shortDate}
                    <br />
                    <small>
                        {facilityDayTypeTranslated} | {keyWordTranslated}
                    </small>
                    <br />
                    <small>
                        {language === 'en' ? 'Target:' : 'Soll:'} {targetTimeTotal} &nbsp;|&nbsp;
                        {language === 'en' ? 'Actual:' : 'Ist:'} {actualTimeTotal} &nbsp;|&nbsp;
                        {language === 'en' ? 'Balance:' : 'Saldo:'} {balanceTotal}
                    </small>
                </div>

                {/* Toggle Intervals */}
                <button
                    className="btn btn-sm btn-outline-dark mt-2 align-self-start"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowIntervals(!showIntervals);
                    }}
                >
                    {showIntervals
                        ? language === 'en'
                            ? 'Hide Intervals'
                            : 'Intervalle ausblenden'
                        : language === 'en'
                            ? 'Show Intervals'
                            : 'Intervalle anzeigen'}
                </button>

                {showIntervals && intervals?.length > 0 && (
                    <div className="mt-2">
                        {intervals.map((interval, idx) => {
                            const intervalTypeTranslated = interval.translateIntervalType(language);
                            return (
                                <div key={idx} className="small">
                                    <strong>{intervalTypeTranslated}: </strong>
                                    {interval.start} - {interval.end}
                                    {interval.comment ? ` (${interval.comment})` : ''}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </Link>
    );
};

export default DayOverview;