import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { calculateStat, calculatePauseStat, formatDuration, sumTimeParts } from '../../../utility/time';

interface DayDetailsSectionProps {
    dayEmployeeStatistics: any;   // Or a more specific type if available
    calledInSick: boolean;
}

/**
 * This sub-component shows the large "Details" section:
 * Saldo, Zeitintervalle, Pausen, etc.
 */
const DayDetailsSection: React.FC<DayDetailsSectionProps> = ({
                                                                 dayEmployeeStatistics,
                                                                 calledInSick,
                                                             }) => {
    const { t } = useTranslation();

    return (
        <Container className="pb-5">
            {/* Saldos */}
            <hr
                className="mt-5 mb-4"
                style={{ backgroundColor: 'white', border: 'none', height: '8px' }}
            />
            <p className="mb-3 font-weight-bold">{t('SALDO_II') as string}</p>
            <Row>
                <Col xs={3}>
                    <p className="mb-1 text-muted">{t('SALDO_TOTAL') as string}</p>
                    <strong>
                        {calculateStat(dayEmployeeStatistics?.value?.dependentBalance)}h
                    </strong>
                </Col>
                <Col xs={3}>
                    <p className="mb-1 text-muted">{t('SALDO_CARE') as string}</p>
                    <strong>
                        {formatDuration(dayEmployeeStatistics?.value?.dependentBalance?.careTimePart)}h
                    </strong>
                </Col>
                <Col xs={3}>
                    <p className="mb-1 text-muted">{t('SALDO_DISPOSAL') as string}</p>
                    <strong>
                        {formatDuration(dayEmployeeStatistics?.value?.dependentBalance?.disposalTimeRegularPart)}h
                    </strong>
                </Col>
                <Col xs={3}>
                    <p className="mb-1 text-muted">{t('SALDO_IRREGULAR_DISPOSAL') as string}</p>
                    <strong>
                        {formatDuration(dayEmployeeStatistics?.value?.dependentBalance?.disposalTimeIrregularPart)}h
                    </strong>
                </Col>
            </Row>

            {/* Time intervals overview */}
            <hr
                className="mt-5 mb-4"
                style={{ backgroundColor: 'white', border: 'none', height: '8px' }}
            />
            <p className="mb-3 font-weight-bold">{t('Detailübersicht Zeitintervalle') as string}</p>
            <Row>
                <Col xs={4}>
                    <p className="mb-1 text-muted">{t('TARGET_TIME_CARE') as string}</p>
                    <strong>
                        {formatDuration(dayEmployeeStatistics?.value?.targetTime?.careTimePart)}h
                    </strong>
                </Col>
                <Col xs={4}>
                    <p className="mb-1 text-muted">{t('ACTUAL_TIME_CARE') as string}</p>
                    <strong>
                        {formatDuration(dayEmployeeStatistics?.value?.actualTime?.actualTimeAccepted?.careTimePart)}h
                    </strong>
                </Col>
                <Col xs={4}>
                    <p className="mb-1 text-muted">{t('BALANCE_CARE') as string}</p>
                    <strong>
                        {formatDuration(dayEmployeeStatistics?.value?.balance?.careTimePart)}h
                    </strong>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col xs={4}>
                    <p className="mb-1 text-muted">{t('TARGET_TIME_DISPOSAL') as string}</p>
                    <strong>
                        {formatDuration(dayEmployeeStatistics?.value?.targetTime?.disposalTimeRegularPart)}h
                    </strong>
                </Col>
                <Col xs={4}>
                    <p className="mb-1 text-muted">{t('ACTUAL_TIME_DISPOSAL') as string}</p>
                    <strong>
                        {formatDuration(
                            dayEmployeeStatistics?.value?.actualTime?.actualTimeAccepted?.disposalTimeRegularPart
                        )}h
                    </strong>
                </Col>
                <Col xs={4}>
                    <p className="mb-1 text-muted">{t('BALANCE_DISPOSAL') as string}</p>
                    <strong>
                        {formatDuration(dayEmployeeStatistics?.value?.balance?.disposalTimeRegularPart)}h
                    </strong>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col xs={4}>
                    <p className="mb-1 text-muted">{t('TARGET_TIME_IRREGULAR') as string}</p>
                    <strong>
                        {formatDuration(dayEmployeeStatistics?.value?.targetTime?.disposalTimeIrregularPart)}h
                    </strong>
                </Col>
                <Col xs={4}>
                    <p className="mb-1 text-muted">{t('ACTUAL_TIME_IRREGULAR') as string}</p>
                    <strong>
                        {formatDuration(
                            dayEmployeeStatistics?.value?.actualTime?.actualTimeAccepted?.disposalTimeIrregularPart
                        )}h
                    </strong>
                </Col>
                <Col xs={4}>
                    <p className="mb-1 text-muted">{t('BALANCE_IRREGULAR') as string}</p>
                    <strong>
                        {formatDuration(dayEmployeeStatistics?.value?.balance?.disposalTimeIrregularPart)}h
                    </strong>
                </Col>
            </Row>

            {/* Pausen und Zeitzuschläge */}
            <hr
                className="mt-5 mb-4"
                style={{ backgroundColor: 'white', border: 'none', height: '8px' }}
            />
            <p className="mb-3 font-weight-bold">{t('Pausen und Zeitzuschläge') as string}</p>
            <Row>
                <Col xs={6}>
                    <p className="mb-1 text-muted">{t('PAUSE_DEDUCTION') as string}</p>
                    <strong>{calculateStat(dayEmployeeStatistics?.value?.actualTime?.pauseDeduction)}h</strong>
                </Col>
                <Col xs={6}>
                    <p className="mb-1 text-muted">{t('TIME_ADDITION') as string}</p>
                    <strong>{calculateStat(dayEmployeeStatistics?.value?.actualTime?.timeAddition)}h</strong>
                </Col>
            </Row>
        </Container>
    );
};

export default DayDetailsSection;