import React, { useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';
import { v4 as uuid } from 'uuid'; // Correct modern import for UUID
import { EmploymentAttributes } from '../../../apis/attributes';
import CloseWorkdaydefiner from '../images/delete-workdays.png';
import InfoModal from '../../Facility/containers/InfoModal';

interface SelectedDayOption {
    label: string;
    value: number;
}

/* -----------------------------------
   Utility / Helper Functions
----------------------------------- */

const freeMultiWorkdays = (): EmploymentAttributes['multiWorkDayDefiner'] => [
    {
        desc: '',
        id: uuid(),
        workDayDefiner: [],
    },
];

const options = (t: any) => [
    { label: t('Sunday'.toUpperCase()), value: 0 },
    { label: t('Monday'.toUpperCase()), value: 1 },
    { label: t('Tuesday'.toUpperCase()), value: 2 },
    { label: t('Wednesday'.toUpperCase()), value: 3 },
    { label: t('Thursday'.toUpperCase()), value: 4 },
    { label: t('Friday'.toUpperCase()), value: 5 },
    { label: t('Saturday'.toUpperCase()), value: 6 },
];

export const daysStrings = (t: any) => [
    t('Sunday'.toUpperCase()),
    t('Monday'.toUpperCase()),
    t('Tuesday'.toUpperCase()),
    t('Wednesday'.toUpperCase()),
    t('Thursday'.toUpperCase()),
    t('Friday'.toUpperCase()),
    t('Saturday'.toUpperCase()),
];

const convertWorkDayDefiners = (workDays: number[], t: any) =>
    workDays?.map((day) => ({
        label: daysStrings(t)[day],
        value: day,
    })) ?? [];

const convertMultiWorkDayDefinersToSelect = (
    multiWorkDays: EmploymentAttributes['multiWorkDayDefiner'],
    t: any,
) =>
    multiWorkDays.map((workDayDefiner) => ({
        desc: workDayDefiner.desc,
        id: workDayDefiner.id,
        workDayDefiner: convertWorkDayDefiners(workDayDefiner.workDayDefiner, t),
    }));

const convertSelectToMultiWorkDayDefiners = (
    multiWorkDays: {
        desc: string;
        id: string;
        workDayDefiner: SelectedDayOption[];
    }[],
): EmploymentAttributes['multiWorkDayDefiner'] =>
    multiWorkDays.map((workDayDefiner) => ({
        desc: workDayDefiner.desc,
        id: workDayDefiner.id,
        workDayDefiner: workDayDefiner.workDayDefiner.map((day) => day.value),
    }));

/* -----------------------------------
   Main Component
----------------------------------- */

export const MultiWorkdayDefiner: React.FC<{
    multiWorkDayDefiner?: EmploymentAttributes['multiWorkDayDefiner'];
    latestResult: (multiWorkDayDefiner: EmploymentAttributes['multiWorkDayDefiner']) => void;
    disabled?: boolean;
}> = ({ multiWorkDayDefiner = freeMultiWorkdays(), latestResult, disabled = false }) => {
    const { t } = useTranslation();
    const [workDaysError, setWorkDaysError] = useState('');

    // Parse the incoming array into a local state with "label/value" pairs
    const [localMultiWorkDayDefiner, setLocalMultiWorkDayDefiner] = useState(() =>
        convertMultiWorkDayDefinersToSelect(multiWorkDayDefiner, t),
    );

    /* -----------------------------------
         Handlers
      ----------------------------------- */

    // Update local state and notify parent
    const updateLocalAndNotify = (newDefiner: typeof localMultiWorkDayDefiner) => {
        setLocalMultiWorkDayDefiner(newDefiner);
        latestResult(convertSelectToMultiWorkDayDefiners(newDefiner));
    };

    // Remove a row
    const handleRemoveRow = (index: number) => {
        if (localMultiWorkDayDefiner.length === 1) {
            setWorkDaysError(t('YOU_CANNOT_REMOVE_LAST_WORKDAY'));
            return;
        }

        const newMultiWorkDayDefiner = localMultiWorkDayDefiner.filter((_, i) => i !== index);

        if (newMultiWorkDayDefiner.length === 0) {
            newMultiWorkDayDefiner.push({
                desc: '',
                id: uuid(),
                workDayDefiner: [],
            });
        }

        updateLocalAndNotify(newMultiWorkDayDefiner);
    };

    // Add a row
    const handleAddRow = () => {
        const lastDefiner = localMultiWorkDayDefiner[localMultiWorkDayDefiner.length - 1];
        if (!lastDefiner.workDayDefiner.length) {
            setWorkDaysError(t('FILL_OUT_WORK_DAY_WARNING'));
            return;
        }

        setWorkDaysError('');
        const newMultiWorkDayDefiner = [
            ...localMultiWorkDayDefiner,
            {
                desc: '',
                id: uuid(),
                workDayDefiner: [],
            },
        ];

        updateLocalAndNotify(newMultiWorkDayDefiner);
    };

    // Update days in a specific row
    const handleChangeRow = (selectedDays: SelectedDayOption[], index: number) => {
        if (selectedDays.length === 0) {
            setWorkDaysError(t('CANNOT_LEAVE_THIS_EMPTY'));
        } else {
            setWorkDaysError('');
        }

        const copy = [...localMultiWorkDayDefiner];
        copy[index].workDayDefiner = selectedDays;

        updateLocalAndNotify(copy);
    };

    /* -----------------------------------
         Render
      ----------------------------------- */

    return (
        <Container className="mt-4 shadow py-4 px-5 rounded">
            <h4 className="mb-1 mt-3">
                <strong>{t('WORK_DAYS')}</strong>{' '}
                <InfoModal content={t('WORKDAY_DEFINER_MODAL') as string} />
            </h4>

            {localMultiWorkDayDefiner.map((workdayDefiner, index) => (
                <Row className="mt-3" key={workdayDefiner.id}>
                    {/* Column: "Week X" label */}
                    <Col xs={3} className="d-flex align-items-center justify-content-center">
                        {localMultiWorkDayDefiner.length > 1 && (
                            <h5 className="m-0 mt-4">
                                {t('WEEK')} {index + 1}
                            </h5>
                        )}
                    </Col>

                    {/* Column: MultiSelect for WorkDays */}
                    <Col xs={7}>
                        <strong>{t('WORK_DAYS')}</strong>
                        <MultiSelect
                            disabled={disabled}
                            className="mt-2 rounded"
                            options={options(t)}
                            value={workdayDefiner.workDayDefiner}
                            onChange={(selectedDays: SelectedDayOption[]) => handleChangeRow(selectedDays, index)}
                            labelledBy="Select"
                        />
                    </Col>

                    {/* Column: Remove Button */}
                    <Col xs={2} className="d-flex align-items-center justify-content-start">
                        <Button
                            className="mt-4 pb-0"
                            variant="outline-light"
                            disabled={disabled}
                            onClick={() => handleRemoveRow(index)}
                        >
                            <Image src={CloseWorkdaydefiner} alt="Delete Workday" width="36px" height="36px" />
                        </Button>
                    </Col>
                </Row>
            ))}

            {/* Add Row Button */}
            <div className="mt-5 d-flex flex-column justify-content-center align-items-end">
                <Button disabled={disabled} onClick={handleAddRow}>
                    {t('ADD_WORKDAY')}
                </Button>
                {Boolean(workDaysError) && <p className="text-danger">{workDaysError}</p>}
            </div>
        </Container>
    );
};

export default MultiWorkdayDefiner;